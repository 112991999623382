import { Tab, Row, Col, Card } from 'react-bootstrap'

const AboutSection = ({data}) => {
   const array = []
   if(data.address) array.push(data.address)
   if(data.city) array.push(data.city)
   if(data.district) array.push(data.district)
   if(data.state) array.push(data.state)
   if(data.country) array.push(data.country)
   if(data.pinCode) array.push(data.pinCode)

	return (
		<Tab.Container id="left-tabs-example" defaultActiveKey="about1">
      <Row>
         <Col md={12} className="">
            <Card>
               <Card.Body className="px-0">
                        <h4>Personal Info</h4>
                        <hr/>
                        <Row className="mb-2">
                           <div className="col-3">
                              <h6>Name:</h6>
                           </div>
                           <div className="col-9">
                              <p className="mb-0">{data.name}</p>
                           </div>  
                        </Row>
                        <Row className="mb-2">
                           <div className="col-3">
                              <h6>Tagline:</h6>
                           </div>
                           <div className="col-9">
                              <p className="mb-0">{data.tagline}</p>
                           </div>  
                        </Row>
                        <Row className="mb-2">
                           <div className="col-3">
                              <h6>Description:</h6>
                           </div>
                           <div className="col-9">
                              <p className="mb-0">{data.description}</p>
                           </div>  
                        </Row>
                        {
                           data.collabKeywords && Array.isArray(data.collabKeywords) && data.collabKeywords.length > 0 && (
                              <Row className="mb-2">
                                 <div className="col-3 d-flex flex-column justify-content-center">
                                    <h6>Keywords:</h6>
                                 </div>
                                 <div className="col-9 d-flex flex-row justify-content-start flex-wrap" style={{gap: "5px"}}>
                                 {data.collabKeywords.map((collab, index) => {
                                    return (
                                    <div key={index}>
                                       <span className="badge text-primary border border-primary">{collab.collabTag}</span>
                                    </div>
                                    );
                                 })}
                                 </div>  
                              </Row>
                           )
                        }

                        <h4 className="mt-5">User Type</h4>
                        <hr />
                        {
                           data.accountType && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>Account Type:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.accountType}</p>
                                 </div>
                              </Row>
                           )
                        }
                        {
                           data.userType1 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>User Type 1:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.userType1}</p>
                                 </div>
                              </Row>
                           )
                        }
                        {
                           data.userType2 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>User Type 2:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.userType2}</p>
                                 </div>
                              </Row>
                           )
                        }
                        {
                           data.userType3 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>User Type 3:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.userType3}</p>
                                 </div>
                              </Row>
                           )
                        }
                        {
                           data.userType4 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>User Type 4:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.userType4}</p>
                                 </div>
                              </Row>
                           )
                        }
                        {
                           data.userType5 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>User Type 5:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.userType4}</p>
                                 </div>
                              </Row>
                           )
                        }


                        <h4 className="mt-5">Contact Information</h4>
                        <hr />
                        {
                           data.contactNo && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>Contact Number:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.contactNo}</p>
                                 </div>
                              </Row>            
                           )
                        }
                        {
                           data.website && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>Website:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">{data.website}</p>
                                 </div>
                              </Row>          
                           )
                        }
                        <Row className="mb-2">
                           <div className="col-3">
                              <h6>Communication Email:</h6>
                           </div>
                           <div className="col-9">
                              <p className="mb-0">{data.comEmail}</p>
                           </div>
                        </Row> 
                        {
                           array.length > 0 && (
                              <Row className="mb-2">
                                 <div className="col-3">
                                    <h6>Address:</h6>
                                 </div>
                                 <div className="col-9">
                                    <p className="mb-0">
                                       {array.join(", ")}
                                    </p>
                                 </div>
                              </Row>
                           )
                        }
               </Card.Body>
            </Card>
         </Col>
      </Row>
    </Tab.Container>
	)
}

export default AboutSection;
import { Col, Row, Image, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import logo from "../../../assets/images/logo.png";
import { IoIosArrowBack } from "react-icons/io";

const WorkInformation = (props) => {
	const [workDepartment, setWorkDepartment] = useState(
    props.userDetails.workDepartment || ""
  );
  const [workIndustry, setWorkIndustry] = useState(
    props.userDetails.workIndustry || ""
  );
  const [workProfession, setWorkProfession] = useState(
    props.userDetails.workProfession || ""
  );
  const [workExperience, setWorkExperience] = useState(
    props.userDetails.workExperience || ""
  );

  useEffect(() => {
    props.setUserDetails({
      ...props.userDetails,
      workDepartment,
      workIndustry,
      workProfession,
      workExperience,
    });
  }, [workDepartment, workIndustry, workProfession, workExperience]);


	return (
  <div md={6} className="bg-white pb-lg-0 position-relative" style={{marginTop: "20px"}}>
      <Row className="justify-content-center px-5">
        <Col md="12" xs="10" className="text-center p-0">
          <div className="d-flex align-items-center">
            <h4 className="text-primary">User Type Information</h4>
          </div>
          <div className="mx-2">
          <Form className="text-start mt-5">          
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="exampleFormControlSelect1" >
                    Select your Work Department<span style={{color:"red"}}>*</span>
                  </Form.Label>
                
                  <select
                    className={workDepartment ? "form-select" : "form-select text-secondary"}
                    id="exampleFormControlSelect1"
                    onChange={(e) => {
                      setWorkDepartment(e.target.value);
                    }}
                  
                    value={props.userDetails.workDepartment || ""}
                  >
                    <option key="key" className="text-secondary">Select your Sector</option>
                    <option>Governament Sector</option>
                    <option>Private Sector</option>
                    <option>Educational & Entertainment Venue</option>
                  </select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label >Enter Your Industry <span style={{color:"red"}}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Industry"
                    onChange={(e) => {
                      setWorkIndustry(e.target.value);
                    }}
                    value={props.userDetails.workIndustry || ""}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Enter Your Profession<span style={{color:"red"}}>*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Profession"
                    onChange={(e) => {
                      setWorkProfession(e.target.value);
                    }}
                    value={props.userDetails.workProfession || ""}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="exampleFormControlSelect1">
                    Select Number of year of work experience<span style={{color:"red"}}>*</span>
                  </Form.Label>
                  <select
                    className={workExperience ? "form-select" : "form-select text-secondary"}
                    id="exampleFormControlSelect1"
                    onChange={(e) => {
                      setWorkExperience(e.target.value);
                    }}
                    value={props.userDetails.workExperience || ""}
                  >
                    <option className="text-secondary">Select Number of year of work experience</option>
                    <option>0-2</option>
                    <option>3-5</option>
                    <option>6-10</option>
                    <option>10-15</option>
                    <option>16-20</option>
                    <option>20+</option>
                  </select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          </div>
        </Col>
      </Row>
    </div>
	)
}

export default WorkInformation;
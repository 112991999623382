import { useState } from "react";
import "../../../../assets/vendor/remixicon/fonts/remixicon.css";

function SavePost() {
  const [isSaved, setIsSaved] = useState(false);
  const handleClick = () => {
    setIsSaved(!isSaved);
  };
  return (
    <>
      {isSaved ? (
        <i
          className="ri-bookmark-fill text-primary fs-3"
          onClick={handleClick}
        ></i>
      ) : (
        <i class="ri-bookmark-line text-secondary fs-3" onClick={handleClick}></i>
      )}
    </>
  );
}

export default SavePost;

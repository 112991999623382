import { axiosAuthInstance } from "../utilities/axios";

export const createPoll = async (info) => {
	try {
    const res = await axiosAuthInstance.post(`/polls/create`, info);
    const data = res && res.data;
    return { data };
  } catch (err) {
    const errRes = (err && err.response) || err.message || "Network Error";
    return { errRes };
  }  
}

export const getRelatedPolls = async () => {
  try {
    const res = await axiosAuthInstance.get(`/polls/get-related`);
    const data = res && res.data;
    return { data };
  } catch (err) {
    const errRes = (err && err.response) || err.message || "Network Error";
    return { errRes };
  }  
}
import React,{useState, useEffect} from 'react'
import {Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link, useNavigate} from 'react-router-dom'
import { BsFacebook, BsLinkedin, BsInstagram, BsTwitterX, BsYoutube } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import defaultUser from '../../../assets/images/defaultUser.png';

// images
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img2 from '../../../assets/images/user/11.png'
import PageTemplate2 from '../../../components/PageTemplate2'
import AboutSection from '../../../components/profile/AboutSection'
import PostsSection from '../../../components/profile/PostsSection'
import ProjectSection from '../../../components/profile/ProjectSection'
import { getProfile } from '../../../api/profile'
import { getProfileMediaPosts } from '../../../api/mediaPost'

const ProfileDetails =() =>{
   const navigate = useNavigate()
   const {id} = useParams()
   const [user, setUser] = useState()
   const [mediaPosts, setMediaPosts] = useState([])

   const fetchProfile = async () => {
      const res = await getProfile(id)
      if(res.errRes) {
         if(res.errRes.data.message) {
            window.alert(res.errRes.data.message)
            return 
         }
         window.alert("User not found")
      }
      if(res.data.success) setUser(res.data.data)
      if(!res.data.success) window.alert("User not found")
   }

   const fetchMediaPosts = async () => {
      const resp = await getProfileMediaPosts(id) 
      if(resp.errRes) {
         if(resp.errRes.response) {
            window.alert(resp.errRes.response.data.message)
            return;
         }
         if(resp.errRes.message) {
            window.alert(resp.errRes.message)
            return;
         }
         console.log(resp)
         return;
      }
      if(resp.data.success) {
         setMediaPosts(resp.data.data)
      }
   }

   useEffect(() => {
      fetchProfile()
      fetchMediaPosts()
   }, [id])

   return user?(
      <>
			<PageTemplate2>
				<Container>
					<Row>
						<Col sm={12}>
                  <Card>
                     <Card.Body className=" profile-page p-0">
                        <div className="profile-header">
                           <div className="position-relative">
                              <img loading="lazy" src={img1} alt="profile-bg" className="rounded img-fluid"/>
                           </div>
                           <div className="user-detail text-center mb-3">
                              <div className="profile-img">
                                 <img loading="lazy" src={user.profilePic?user.profilePic:defaultUser} alt="profile-img1" className="avatar-130 img-fluid" style={{width: "150px", height: "150px", objectFit: "cover",background: "white"}} />
                              </div>
                              <div className="profile-detail">
                                 <h3>{user.name}</h3>
                              </div>
                           </div>
                           <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                              <div className="social-links">
                                 <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0" style={{gap: "5px"}}>
                                    <li className="text-center pe-3">
                                       <Link to="#"><BsFacebook style={{width: "20px", height: "20px"}}/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><BsTwitterX style={{width: "20px", height: "20px"}}/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><BsInstagram style={{width: "20px", height: "20px"}}/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><BsYoutube style={{width: "20px", height: "20px"}}/></Link>
                                    </li>
                                    <li className="text-center md-pe-3 pe-0">
                                       <Link to="#"><BsLinkedin style={{width: "20px", height: "20px"}}/></Link>
                                    </li>
                                 </ul>
                              </div>
                              <div className="social-info">
                                 <button className='btn btn-primary rounded-pill' style={{width: "100px"}}>Collaborate</button>
                              </div>
                          </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
					</Row>
					<Row>
						<Tab.Container defaultActiveKey="about">
							<Card className="p-0">
                <Card.Body className="p-0">
                  <div className="user-tabing">
                    <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                      <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                         <Nav.Link  href="#pills-about-tab" eventKey="about" role="button" className="text-center p-3">About</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className=" col-12 col-sm-4 p-0">
                         <Nav.Link  href="#pills-friends-tab"  eventKey="posts" role="button" className="text-center p-3">Posts</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                         <Nav.Link  href="#pills-photos-tab"  eventKey="projects" role="button" className="text-center p-3">Ongoing Projects</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
							<Col sm={12}>
								<Tab.Content>
									<Tab.Pane eventKey="about">
										<AboutSection data={user}/>
									</Tab.Pane>
									<Tab.Pane eventKey="posts">
										<PostsSection mediaPosts={mediaPosts} user={user}/>
									</Tab.Pane>
									<Tab.Pane eventKey="projects">
										<ProjectSection ongoing/>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Tab.Container>
					</Row>
				</Container>
			</PageTemplate2>   
      </>
   ): (
      <div>
         User not found
      </div>
   )
}
export default ProfileDetails;
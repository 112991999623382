//libray import
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from 'react';

import MediaPost from "./components/Media";
import PollCard from "./components/PollCard";
import Question from "./components/Question";
import Project from "../projects/Project";

// custom css to make the tab bar different from the default
import "../index.css"
import PageTemplate1 from "../../../components/PageTemplate1";
import { getLocalStorage } from "../../../utilities/localStorage";
import { getRelatedMediaPosts } from "../../../api/mediaPost";
import { getRelatedPolls } from "../../../api/polls";

//home component
function HomeIndex() {
  return (
    <PageTemplate1>
      <TabBar />
    </PageTemplate1>
  );
}

export default HomeIndex;

// tabbar component for the home page

function TabBar() {
  const [mediaPosts, setMediaPosts] = useState([])
  const [polls, setPolls] = useState([])
  const [user, setUser] = useState({})

  const fetchRelatedMediaPosts = async () => {
    const resp = await getRelatedMediaPosts()
    if(resp.errRes) {
      if(resp.errRes.response) {
        window.alert(resp.errRes.response.data.message)
        return;
      }
      if(resp.errRes.message) {
        window.alert(resp.errRes.message)
        return;
      }
      console.log(resp)
      return;
    }
    if(resp.data.success) {
      console.log(resp.data.success) 
      setMediaPosts(resp.data.data)
    }
  }

  const fetchRelatedPolls = async () => {
    const resp = await getRelatedPolls()
    if(resp.errRes) {
      if(resp.errRes.response) {
        window.alert(resp.errRes.response.data.message)
        return;
      }
      if(resp.errRes.message) {
        window.alert(resp.errRes.message)
        return;
      }
      console.log(resp)
      return;
    }
    if(resp.data.success) {
      console.log(resp.data.data)
      setPolls(resp.data.data)
    }
  }

  useEffect(() => {
    const userData = getLocalStorage("user")
    setUser(userData)
    fetchRelatedMediaPosts()
    fetchRelatedPolls()
  }, [])

  return (
    <div>
    <h1 className="p-3 mt-3 text-primary">Good Morning {user.name}!</h1>
    <Tabs defaultActiveKey="media" id="fill-tab-example" className="mb-3"  fill>
      <Tab eventKey="media" title="Media">
        {
          mediaPosts.map((item, index) => (
            <MediaPost post={item} key={index} user={user}/>
          ))
        }
      </Tab>
      <Tab eventKey="home" title="Project / Initiative" className="bg-white">
        <Project name="1" />
        <Project name="2" />
        <Project name="3" />
        <Project name="4" />
        <Project name="5" />
      </Tab>
      <Tab eventKey="polling" title="Polls / Questions">
        {
          polls && polls.map((poll, index) => {
            return (poll.options && poll.options.length > 0) ? (
              <PollCard poll={poll} key={index} user={user}/>
            ): (
              <Question poll={poll} key={index} user={user}/>
            )
          })
        }
      </Tab>

    </Tabs>
    </div>
  );
}


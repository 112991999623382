import { Button, Card, Col, Dropdown, ProgressBar } from "react-bootstrap";
import { useState } from "react";
import user01 from "../../../../assets/images/user/01.jpg";
import Comment from "./Comment";
import { Modal } from "react-bootstrap";
import  LikeComponent from "./LikeComponent";
import SavePost from "./SavePost";
import defaultUser from '../../../../assets/images/defaultUser.png';


// this is the poll card component for the home page
function PollCard({poll, user}) {
  const [like, setLike] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [commentNumber, setCommentNumber] = useState(1);

  const createdAt = poll.createdAt? (new Date(poll.createdAt).toLocaleDateString("en-IN", {day: "2-digit", year: "2-digit", month: "2-digit"})): (new Date()).toLocaleDateString("en-IN", {day: "2-digit", year: "2-digit", month: "2-digit"}) 

  function handleClick() {
    setClicked(!clicked);
  }

  function handleMoreComments() {
    setCommentNumber(commentNumber + 2);
  }

  const handleLike = () => {

  }

  const handleUnlike = () => {

  }

  return (
    <Col sm={12}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body>
          <div className="user-post-data">
            <div className="d-flex justify-content-between">
              <div className="me-3">
                <img className="rounded-circle img-fluid" src={poll.userId.profilePic?poll.userId.profilePic:defaultUser} alt="" style={{width: "40px", height: "40px"}}/>
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="mb-0 d-inline-block">{poll.userId.name}</h5>
                    <p className="mb-0 text-primary">{createdAt}</p>
                  </div>
                  <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <div className="h4 material-symbols-outlined">
                              <i className="ri-save-line"></i>
                            </div>
                            <div className="data ms-2">
                              <h6>Save Post</h6>
                              <p className="mb-0">
                                Add this to your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-user-unfollow-line h4"></i>
                            <div className="data ms-2">
                              <h6>Unfollow User</h6>
                              <p className="mb-0">
                                Stop seeing posts but stay friends.
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-notification-line h4"></i>
                            <div className="data ms-2">
                              <h6>Report</h6>
                              <p className="mb-0">Report this post</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p>
              {poll.caption}
            </p>
          </div>
          <PollComponent handleClick={handleClick} poll={poll}/>
          <div className="comment-area mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="like-data">
                    <div className="d-flex align-items-center">
                     <LikeComponent handleLike={handleLike} handleUnlike={handleUnlike} likes={poll.likes} user={user}/>
                    </div>
                  </div>
                  <div className="align-items-center mx-2">
                  <span
                    className=" text-primary"
                  >
                    {poll.likes.length? poll.likes.length: 0} Likes
                  </span>
                  </div>
                </div>
                <div
                  className="total-comment-block"
                  onClick={() => {
                    console.log("comment");
                  }}
                >
                  <span className="text-primary">{poll.comments.length} Comments</span>
                </div>
              </div>

              <div
                className="d-flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  console.log("bookmark");
                }}
              >
                <SavePost />
              </div>
            </div>
            <hr />
            <ul
              className="post-comments list-inline p-0 m-0 "
              id="comments-section"
            >
              {Array.from({ length: commentNumber }, (_, index) => (
                <Comment />
              ))}
            </ul>

            <div
              className="mx-1  text-secondary"
              style={{ cursor: "pointer" }}
              onClick={handleMoreComments}
            >
              View more comment
            </div>
            <form className="comment-text d-flex align-items-center mt-3">
              <input
                type="text"
                className="form-control rounded mx-2"
                placeholder="Enter Your Comment"
              />
              <Button variant="outline-primary" type="submit">
                Post
              </Button>
            </form>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default PollCard;


function PollComponent({ poll }) {
  const [clicked, setClicked] = useState(false);
  function handleClick() {
    setClicked(!clicked);
  }

  return (
    <div
      className="user-post rounded border border-secondary-subtle m-2 p-2 "
      style={{
        backgroundColor: "#f8f9fa",
      }}
    >
      <div>
        <h5 className="m-1 p-1" style={{ fontWeight: "bold" }}>
          {" "}
          {poll.question}
        </h5>
      </div>
      <div className="w-75 mx-auto">
        {clicked ? (
          <PollResult />
        ) : (
          <>
            {
              poll && poll.options && poll.options.map((item, index) => (
                <PollOption value={item.text} handleClick={handleClick} key={index}/>
              ))
            }
          </>
        )}
      </div>
    </div>
  );
}

function PollOption({ value, handleClick }) {
  return (
    <div className="d-flex my-3 justify-content-center">
      <Button
        variant="outline-primary"
        className="rounded-sides w-100 "
        style={{
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
          fontWeight: "bold",
        }}
        onClick={handleClick}
      >
        {value}
      </Button>
    </div>
  );
}

function PollResult() {
  return (
    <div className="">
      <PollResultOption now={30} value={"Due to lack of policies"} />
      <PollResultOption now={60} value={"Government is not considering this issue"} />
      <PollResultOption now={30} value={"The cost of grains have been sky rocketed"} />
      <PollResultOption now={90} value={"Due to unemployment among adults."} />
    </div>
  );
}

function PollResultOption({ value, now }) {
  return (
    <div className="mx-2 my-2 p-2">
      <span>
        <ProgressBar
          label={value + " " + now + "%"}
          now={now}
          variant="primary"
          style={{ height: "30px", fontSize: "1.0rem" }}
          className="text-start"
        />
      </span>
    </div>
  );
}

function LikeElement() {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img src={user01} className="img-fluid rounded-circle" alt="" />
            <h6 className="mb-0 ms-3">Anna Sthesia</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

import { Tab, Tabs } from "react-bootstrap";
import MediaPost from "../../impactshaala/pages/home/components/Media";
import PollCard from "../../impactshaala/pages/home/components/PollCard";
import Question from "../../impactshaala/pages/home/components/Question";
import ProjectCard from "./ProjectCard";


const PostsSection = ({
  myProfile = false,
  mediaPosts = [],
  user,
  handleDeletePost
}) => {
	return (
		<Tabs defaultActiveKey="media" id="fill-tab-example" className="mb-3"  fill>
      <Tab eventKey="media" title="Media">
        {
          mediaPosts.map((post, index) => (
            <MediaPost key={index} post={post} user={user} handleDelete={handleDeletePost} postId={post._id}/>
          ))
        }
      </Tab>
      <Tab eventKey="home" title="Project / Initiative" className="bg-white">
        <ProjectCard name="1" myProfile={myProfile}/>
        <ProjectCard name="2" myProfile={myProfile}/>
        <ProjectCard name="3" myProfile={myProfile}/>
        <ProjectCard name="4" myProfile={myProfile}/>
        <ProjectCard name="5" myProfile={myProfile}/>
      </Tab>
      <Tab eventKey="polling" title="Polls / Questions">
        <PollCard />
        <Question />
        <PollCard />
        <Question />
        <PollCard />
        <Question />
        <PollCard />
      </Tab>
    </Tabs>
	)
}

export default PostsSection;
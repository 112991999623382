import { Button, Card, Col, Dropdown, Form } from "react-bootstrap";

import user01 from "../../../../assets/images/user/01.jpg";
import { useState } from "react";
import Comment from "./Comment";
import LikeModal from "./LikeModal";
import LikeComponent from "./LikeComponent";
import SavePost from "./SavePost";
import defaultUser from '../../../../assets/images/defaultUser.png';

function Question({poll, user}) {
  const [like, setLike] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [commentNumber, setcommentNumber] = useState(2);

  const createdAt = poll.createdAt? (new Date(poll.createdAt).toLocaleDateString("en-IN", {day: "2-digit", year: "2-digit", month: "2-digit"})): (new Date()).toLocaleDateString("en-IN", {day: "2-digit", year: "2-digit", month: "2-digit"}) 


  const handleClick = () => {
    setClicked(!clicked);
  };

  function handleMoreComment() {
    setcommentNumber(commentNumber + 2);
  }

  const handleLike = () => {

  }

  const handleUnlike = () => {

  }

  return (
    <Col sm={12}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body>
          <div className="user-post-data">
            <div className="d-flex justify-content-between">
              <div className="me-3">
                <img className="rounded-circle img-fluid" src={poll.userId.profilePic?poll.userId.profilePic:defaultUser} alt="" style={{width: "40px", height: "40px"}}/>
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="mb-0 d-inline-block">{poll.userId.name}</h5>
                    <p className="mb-0 text-primary">{createdAt}</p>
                  </div>
                  <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <div className="h4 material-symbols-outlined">
                              <i className="ri-save-line"></i>
                            </div>
                            <div className="data ms-2">
                              <h6>Save Post</h6>
                              <p className="mb-0">
                                Add this to your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-user-unfollow-line h4"></i>
                            <div className="data ms-2">
                              <h6>Unfollow User</h6>
                              <p className="mb-0">
                                Stop seeing posts but stay friends.
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <i className="ri-notification-line h4"></i>
                            <div className="data ms-2">
                              <h6>Report</h6>
                              <p className="mb-0">Report this post</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p>
              {poll.question}
            </p>
            <p className="text-secondary">{poll.comments.length?poll.comments.length:0} answers</p>
          </div>

          <div className="comment-area mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="like-data">
                    <>
                      {/* <img src={icon1} className="img-fluid" alt="" /> */}
                      <LikeComponent handleLike={handleLike} handleUnlike={handleUnlike} user={user} likes={poll.likes}/>
                    </>
                  </div>
                  <div className="total-like-block ms-2 me-3">
                    <LikeModal />
                  </div>
                </div>
              </div>
              {/* <ShareOffcanvas /> */}
               <SavePost />
            </div>
            <hr />
            <ul className="post-comments list-inline p-0 m-0">
              {Array.from({ length: commentNumber }, (_, index) => (
                <Comment key={index} />
              ))}
              <div
                className="d-flex"
                style={{ cursor: "pointer" }}
                onClick={handleMoreComment}
              >
                <div>
                  <span class="material-symbols-outlined">more_horiz</span>
                </div>
                <div className="mx-1  text-secondary">View more answers</div>
              </div>
            </ul>
            <form className="comment-text d-flex align-items-center mt-3">
              <input
                type="textarea"
                className="form-control rounded m-2"
                placeholder="Enter Your Answer..."
              />
              <Button variant="outline-primary" type="submit">
                Post
              </Button>
            </form>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Question;
